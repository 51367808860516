import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, EXPIRE_ALERT } from '../Utils/API';

// Async thunk for fetching expiry alerts
export const fetchExpireAlerts = createAsyncThunk(
  'expireAlert/fetchExpireAlerts',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${BASE_URL + EXPIRE_ALERT}`); // Adjust the endpoint
      return response?.data?.data; // Assuming API returns the alert data in `data` field
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching expiry alerts');
    }
  },
);

const expireAlertSlice = createSlice({
  name: 'expireAlert',
  initialState: {
    expiredStocks: [], // Stocks already marked as expired
    loading: false,
    error: null,
  },
  reducers: {
    // You can add additional reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpireAlerts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExpireAlerts.fulfilled, (state, action) => {
        state.loading = false;
        state.expiredStocks = action.payload || [];
      })
      .addCase(fetchExpireAlerts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch expiry alerts';
      });
  },
});

export default expireAlertSlice.reducer;
