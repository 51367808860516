import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from 'reactstrap';
import { FaEllipsisV, FaDownload } from 'react-icons/fa';
import { fetchStock } from '../../../store/stockSlice';
import ToastNotification from '../../../Utils/ToastNotification';

const AllStockList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const { stockList } = useSelector((state) => state.stock);

  const handleEdit = (stock) => {
    navigate(`/skch-pharmacy/edit-stock/${stock.id}`, { state: { stock } });
  };

  const handleDelete = async (stock) => {
    try {
      const response = await axios.delete(
        `https://pharmacyapi.hybridtech.in/api/stock/stock.php?id=${stock.id}`,
      );

      if (response.data.statusCode === 1) {
        alert(response.data.message); // Show success message
        dispatch(fetchStock());
      } else {
        ToastNotification('error', 'Failed to delete the stock record. Please try again.');
      }
    } catch (error) {
      ToastNotification('error', 'An error occurred while deleting the stock. Please try again.');
    }
  };

  const handleDownload = async (stockId) => {
    try {
      const response = await axios.get(
        `https://pharmacyapi.hybridtech.in/api/stock/downloadStockReport.php?id=${stockId}`,
      );

      if (response.data.message === 'PDF generated successfully.' && response.data.link) {
        // Redirect to the PDF link
        window.open(response.data.link, '_blank');
      } else {
        alert('Failed to generate the stock report. Please try again.');
      }
    } catch (error) {
      console.error('Error downloading the stock report:', error);
      alert('An error occurred while downloading the stock report. Please try again.');
    }
  };

  useEffect(() => {
    dispatch(fetchStock());
  }, [dispatch]);

  // Filtered stock list based on search query
  const filteredStockList = stockList.filter((stock) =>
    stock.medicineName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <div>
      {/* Search Input */}
      <div className="mb-3">
        <Input
          type="text"
          placeholder="Search by medicine name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Stock Table */}
      <Table className="align-middle">
        <thead>
          <tr>
            <th>No</th>
            <th>Medicine Name</th>
            <th>MRP</th>
            <th>Quantity</th>
            <th>Purchase Price</th>
            <th>Expire Date</th>
            <th>Supplier</th>
            <th>Invoice</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredStockList.map((stock, index) => (
            <tr key={stock.id}>
              <td>{index + 1}</td>
              <td>{stock.medicineName}</td>
              <td>{stock.mrp}</td>
              <td>{stock.stockQuantity}</td>
              <td>{stock.purchasePrice}</td>
              <td>{stock.expireDate}</td>
              <td>{stock.distributorName}</td>
              <td>
                {stock?.file === 'true' ? (
                  <FaDownload
                    onClick={() => handleDownload(stock.id)}
                    style={{ cursor: 'pointer', color: '#fb9678' }}
                  />
                ) : (
                  'No Report'
                )}
              </td>
              <td className="d-flex align-items-center gap-4">
                <UncontrolledDropdown>
                  <DropdownToggle tag="div">
                    <div style={{ cursor: 'pointer' }}>
                      <FaEllipsisV />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleEdit(stock)}>Edit</DropdownItem>
                    <DropdownItem onClick={() => handleDelete(stock)}>Delete</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AllStockList;
