import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BASE_URL, UPDATE_STOCK } from '../../Utils/API'; // Make sure to define the endpoint in your API
import ToastNotification from '../../Utils/ToastNotification';

const EditStock = () => {
  const { id } = useParams(); // Get the stock ID from the route parameters
  const navigate = useNavigate();
  const { stockList } = useSelector((state) => state.stock); // Assuming stock is part of your Redux store
  const [formData, setFormData] = useState({
    medicineName: '',
    stockQuantity: '',
    mrp: '',
    packageType: '',
    purchaseInvoice: '', // Base64 encoded string for the file
    setExpireDate: '',
    purchasePrice: '',
    minimumStockLevel: '',
    batchNo: '',
    distributorName: '',
    purchaseInvoiceNo: '',
  });

  useEffect(() => {
    // Find the stock item by ID from the stock list
    const stockData = stockList.find((stock) => stock.id === parseInt(id, 10));

    if (stockData) {
      // Set the form data with the found stock data
      setFormData({
        medicineName: stockData.medicineName || '',
        stockQuantity: stockData.stockQuantity || '',
        mrp: stockData.mrp || '',
        packageType: stockData.packageType || '',
        purchaseInvoice: stockData.purchaseInvoice || '',
        setExpireDate: stockData.setExpireDate || '',
        purchasePrice: stockData.purchasePrice || '',
        minimumStockLevel: stockData.minimumStockLevel || '',
        batchNo: stockData.batchNo || '',
        distributorName: stockData.distributorName || '',
        purchaseInvoiceNo: stockData.purchaseInvoiceNo || '',
      });
    } else {
      console.error('Stock not found in the stock list');
    }
  }, [id, stockList]);

  // Helper function to convert file to Base64
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  };

  const handleInputChange = async (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      // Convert the file to Base64
      const file = files[0];
      const base64File = await convertFileToBase64(file);
      setFormData({ ...formData, purchaseInvoice: base64File });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add id to formData
    const updatedFormData = { ...formData, id };

    axios
      .put(`${BASE_URL}${UPDATE_STOCK}?id=${id}`, updatedFormData) // Sending id as part of the formData
      .then((response) => {
        if (response?.data?.statusCode === '1') {
          console.log('Stock details updated successfully', response);

          ToastNotification('success', response?.data?.message);
          navigate('/stock-list'); // Redirect after successful update
        } else {
          ToastNotification('error', response?.data?.message);
        }
      })
      .catch((error) => {
        console.error('There was an error updating the stock data!', error);
        ToastNotification('error', 'Error updating stock details. Please try again.');
      });
  };

  return (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardTitle tag="h4" className="border-bottom bg-primary p-3 mb-0 text-white">
              Edit Stock
            </CardTitle>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Medicine Name</Label>
                      <Input
                        type="text"
                        name="medicineName"
                        value={formData.medicineName}
                        onChange={handleInputChange}
                        placeholder="Enter medicine name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Stock Quantity</Label>
                      <Input
                        type="number"
                        name="stockQuantity"
                        value={formData.stockQuantity}
                        onChange={handleInputChange}
                        placeholder="Enter stock quantity"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>MRP</Label>
                      <Input
                        type="number"
                        name="mrp"
                        value={formData.mrp}
                        onChange={handleInputChange}
                        placeholder="Enter MRP"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Package Type</Label>
                      <Input
                        type="text"
                        name="packageType"
                        value={formData.packageType}
                        onChange={handleInputChange}
                        placeholder="Enter package type"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Set Expiry Date</Label>
                      <Input
                        type="date"
                        name="setExpireDate"
                        value={formData.setExpireDate}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Purchase Price</Label>
                      <Input
                        type="number"
                        name="purchasePrice"
                        value={formData.purchasePrice}
                        onChange={handleInputChange}
                        placeholder="Enter purchase price"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Minimum Stock Level</Label>
                      <Input
                        type="number"
                        name="minimumStockLevel"
                        value={formData.minimumStockLevel}
                        onChange={handleInputChange}
                        placeholder="Enter minimum stock level"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Batch No.</Label>
                      <Input
                        type="text"
                        name="batchNo"
                        value={formData.batchNo}
                        onChange={handleInputChange}
                        placeholder="Enter batch number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Distributor Name</Label>
                      <Input
                        type="text"
                        name="distributorName"
                        value={formData.distributorName}
                        onChange={handleInputChange}
                        placeholder="Enter distributor name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Purchase Invoice No.</Label>
                      <Input
                        type="text"
                        name="purchaseInvoiceNo"
                        value={formData.purchaseInvoiceNo}
                        onChange={handleInputChange}
                        placeholder="Enter purchase invoice number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label>Purchase Invoice (Upload File)</Label>
                  <Input type="file" name="purchaseInvoice" onChange={handleInputChange} />
                </FormGroup>
                <Button type="submit" color="primary">
                  Update Stock
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EditStock;
