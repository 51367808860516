import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import axios from 'axios';
import ToastNotification from '../../Utils/ToastNotification';
import { BASE_URL, ADD_STOCK } from '../../Utils/API';

const AddStock = () => {
  const [stockData, setStockData] = useState({
    medicineName: '',
    stockQuantity: '',
    mrp: '',
    packageType: '',
    purchaseInvoice: null, // For Base64 encoded file
    expireDate: '',
    purchasePrice: '',
    minimumStockLevel: '',
    batchNo: '',
    distributorName: '',
    purchaseInvoiceNo: '',
  });

  // To handle file and convert to Base64
  const handleFileRead = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setStockData((prevData) => ({
        ...prevData,
        purchaseInvoice: reader.result, // Base64 encoded file
      }));
    };
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file' && files.length > 0) {
      handleFileRead(files[0]);
    } else {
      setStockData({ ...stockData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(BASE_URL + ADD_STOCK, stockData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success === true) {
        ToastNotification('success', 'Stock added successfully');
        setStockData({
          medicineName: '',
          stockQuantity: '',
          mrp: '',
          packageType: '',
          purchaseInvoice: null,
          expireDate: '',
          purchasePrice: '',
          minimumStockLevel: '',
          batchNo: '',
          distributorName: '',
          purchaseInvoiceNo: '',
        });
      } else if (response.data.statusCode === 0) {
        ToastNotification('warn', 'Stock already exists');
      } else {
        ToastNotification('error', response?.data?.message);
      }
    } catch (error) {
      console.error('Error adding stock:', error);
      ToastNotification('error', 'An error occurred while adding the stock');
    }
  };

  return (
    <div>
      <Row>
        <Col md="12">
          <Card>
            <CardTitle tag="h4" className="border-bottom bg-primary p-3 mb-0 text-white">
              Add Stock
            </CardTitle>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Medicine Name</Label>
                      <Input
                        type="text"
                        name="medicineName"
                        value={stockData.medicineName}
                        onChange={handleChange}
                        placeholder="Enter medicine name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Stock Quantity</Label>
                      <Input
                        type="number"
                        name="stockQuantity"
                        value={stockData.stockQuantity}
                        onChange={handleChange}
                        placeholder="Enter stock quantity"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>MRP</Label>
                      <Input
                        type="number"
                        name="mrp"
                        value={stockData.mrp}
                        onChange={handleChange}
                        placeholder="Enter MRP"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Package Type</Label>
                      <Input
                        type="text"
                        name="packageType"
                        value={stockData.packageType}
                        onChange={handleChange}
                        placeholder="Enter package type"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Purchase Invoice</Label>
                      <Input type="file" name="purchaseInvoice" onChange={handleChange} />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Set Expire Date</Label>
                      <Input
                        type="date"
                        name="expireDate"
                        value={stockData.expireDate}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Purchase Price</Label>
                      <Input
                        type="number"
                        name="purchasePrice"
                        value={stockData.purchasePrice}
                        onChange={handleChange}
                        placeholder="Enter purchase price"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Minimum Stock Level</Label>
                      <Input
                        type="number"
                        name="minimumStockLevel"
                        value={stockData.minimumStockLevel}
                        onChange={handleChange}
                        placeholder="Enter minimum stock level"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Batch No.</Label>
                      <Input
                        type="text"
                        name="batchNo"
                        value={stockData.batchNo}
                        onChange={handleChange}
                        placeholder="Enter batch number"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label>Distributor Name</Label>
                      <Input
                        type="text"
                        name="distributorName"
                        value={stockData.distributorName}
                        onChange={handleChange}
                        placeholder="Enter distributor name"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label>Purchase Invoice No</Label>
                      <Input
                        type="text"
                        name="purchaseInvoiceNo"
                        value={stockData.purchaseInvoiceNo}
                        onChange={handleChange}
                        placeholder="Enter purchase invoice number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Button type="submit" className="btn btn-success">
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddStock;
