import { Card, CardBody } from 'reactstrap';
import AllExpireAlertList from './components/AllExprireAlertList';

const ExprireAlert = () => {
  return (
    <Card>
      <CardBody>
        <AllExpireAlertList />
      </CardBody>
    </Card>
  );
};

export default ExprireAlert;
