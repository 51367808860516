import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, LOW_STOCK_ALERT } from '../Utils/API';

// Async thunk for fetching low stock alerts
export const fetchLowStockAlerts = createAsyncThunk(
  'lowStockAlert/fetchLowStockAlerts',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${BASE_URL + LOW_STOCK_ALERT}`); // Adjust the endpoint
      return response?.data?.data; // Assuming API returns low stock data in `data` field
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching low stock alerts');
    }
  },
);

const lowStockAlertSlice = createSlice({
  name: 'lowStockAlert',
  initialState: {
    lowStockItems: [], // Stores low stock items fetched from the API
    loading: false, // Tracks loading state for API calls
    error: null, // Stores error message if API call fails
  },
  reducers: {
    // Add reducers here if you need additional functionality
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLowStockAlerts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLowStockAlerts.fulfilled, (state, action) => {
        state.loading = false;
        state.lowStockItems = action.payload || []; // Save the fetched low stock items
      })
      .addCase(fetchLowStockAlerts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch low stock alerts';
      });
  },
});

export default lowStockAlertSlice.reducer;
