import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, FETCH_STOCK } from '../Utils/API';

// Async thunk for fetching stock data
export const fetchStock = createAsyncThunk('stock/fetchStock', async (_, thunkAPI) => {
  try {
    const response = await axios.get(BASE_URL + FETCH_STOCK);
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Error fetching stock data');
  }
});

const stockSlice = createSlice({
  name: 'stock',
  initialState: {
    stockList: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Additional non-async reducers can be added here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStock.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStock.fulfilled, (state, action) => {
        state.loading = false;
        state.stockList = action.payload;
      })
      .addCase(fetchStock.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch stock data';
      });
  },
});

export default stockSlice.reducer;
