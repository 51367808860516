import React, { useEffect, useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input } from 'reactstrap';
import { fetchLowStockAlerts } from '../../../store/lowStockAlertSlice';
import ToastNotification from '../../../Utils/ToastNotification';

const AllLowStockAlertList = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const { lowStockItems, loading, error } = useSelector((state) => state.lowStockAlert);

  useEffect(() => {
    dispatch(fetchLowStockAlerts());
  }, [dispatch]);

  // Filtered low stock list based on search query
  const filteredLowStockItems = lowStockItems.filter((item) =>
    item.medicineName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (loading) return <p>Loading...</p>;
  if (error) {
    ToastNotification('error', 'Failed to load low stock alerts.');
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      {/* Search Input */}
      <div className="mb-3">
        <Input
          type="text"
          placeholder="Search by medicine name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Low Stock Alert Table */}
      <Table className="align-middle">
        <thead>
          <tr>
            <th>No</th>
            <th>Medicine Name</th>
            <th>Batch No</th>
            <th>MRP</th>
            <th>Quantity</th>
            <th>Minimum Stock Level</th>
            <th>Package Type</th>
            <th>Purchase Price</th>
            <th>Expire Date</th>
            <th>Distributor</th>
            <th>Purchase Invoice</th>
          </tr>
        </thead>
        <tbody>
          {filteredLowStockItems.length > 0 ? (
            filteredLowStockItems.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <span style={{ color: 'red' }}>
                    <FaExclamationTriangle style={{ marginRight: 5 }} />
                    {item.medicineName}
                  </span>
                </td>
                <td>{item.batchNo}</td>
                <td>{item.mrp}</td>
                <td>{item.stockQuantity}</td>
                <td>{item.minimumStockLevel}</td>
                <td>{item.packageType}</td>
                <td>{item.purchasePrice}</td>
                <td>{item.expireDate}</td>
                <td>{item.distributorName}</td>
                <td>{item.purchaseInvoice}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11" className="text-center">
                No low stock alerts found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AllLowStockAlertList;
