import { Card, CardBody } from 'reactstrap';
import AllStockList from './components/AllStockList';

const AllStock = () => {
  return (
    <Card>
      <CardBody>
        <AllStockList />
      </CardBody>
    </Card>
  );
};

export default AllStock;
