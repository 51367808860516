import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input } from 'reactstrap';
import { fetchExpireAlerts } from '../../../store/expireAlertSlice';
import ToastNotification from '../../../Utils/ToastNotification';

const AllExpireAlertList = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const { expiredStocks, loading, error } = useSelector((state) => state.expiredStocks);
  console.log('expireAlert: ', expiredStocks);

  useEffect(() => {
    dispatch(fetchExpireAlerts());
  }, [dispatch]);

  // Filtered expire alert list based on search query
  const filteredExpireAlerts = expiredStocks?.filter((item) =>
    item.medicineName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const isExpired = (expireDate) => {
    const today = moment().startOf('day');
    const expiry = moment(expireDate);
    return expiry.isBefore(today);
  };

  if (loading) return <p>Loading...</p>;
  if (error) {
    ToastNotification('error', 'Failed to load expire alerts.');
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      {/* Search Input */}
      <div className="mb-3">
        <Input
          type="text"
          placeholder="Search by medicine name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Expire Alert Table */}
      <Table className="align-middle">
        <thead>
          <tr>
            <th>No</th>
            <th>Medicine Name</th>
            <th>Batch No</th>
            <th>MRP</th>
            <th>Quantity</th>
            <th>Package Type</th>
            <th>Purchase Price</th>
            <th>Expire Date</th>
            <th>Distributor</th>
            <th>Purchase Invoice</th>
          </tr>
        </thead>
        <tbody>
          {filteredExpireAlerts?.length > 0 ? (
            filteredExpireAlerts?.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  {isExpired(item.expireDate) ? (
                    <span style={{ color: 'red' }}>
                      <FaExclamationTriangle style={{ marginRight: 5 }} />
                      {item.medicineName}
                    </span>
                  ) : (
                    item.medicineName
                  )}
                </td>
                <td>{item.batchNo}</td>
                <td>{item.mrp}</td>
                <td>{item.stockQuantity}</td>
                <td>{item.packageType}</td>
                <td>{item.purchasePrice}</td>
                <td>{item.expireDate}</td>
                <td>{item.distributorName}</td>
                <td>{item.purchaseInvoice}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                No expired medicines found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AllExpireAlertList;
